import React, { useState, useEffect } from 'react';
import { ResponsiveLine }             from '@nivo/line';
import { ResponsiveBar  }             from '@nivo/bar';
import { ResponsivePie  }             from '@nivo/pie';
import utils                          from './CommonUtilities';
import                                     './Graph.css';
import {Select}                       from '@material-ui/core';
import InputLabel                     from '@material-ui/core/InputLabel';
import MenuItem                       from '@material-ui/core/MenuItem';
import FormControl                    from '@material-ui/core/FormControl';

const bDebug    = false;
const separator = '●';
const RANGES_SEPARATOR = '^';
const asColors  = [
     'hsl( 30,75%,70%)' // 'Arancione'
    ,'hsl(120,55%,80%)' // 'Verde'
    ,'hsl(210,75%,70%)' // 'Blue'
    ,'hsl(300,55%,80%)' // 'Viola'
    ,'hsl( 90,75%,70%)' // 'Verde'
    ,'hsl(  0,55%,80%)' // 'Rosso'
    ,'hsl(180,65%,60%)' // 'Blu'
    ,'hsl(270,55%,80%)' // 'Viola'
    ,'hsl( 60,75%,70%)' // 'Giallo'
    ,'hsl(150,55%,80%)' // 'Verde'
    ,'hsl(240,75%,70%)' // 'Blu'
    ,'hsl(330,55%,80%)' // 'Viola'
    
    ,'hsl(120,75%,70%)' // 'Verde'
    ,'hsl(210,55%,80%)' // 'Blue'
    ,'hsl(300,75%,70%)' // 'Viola'
    ,'hsl( 90,55%,80%)' // 'Verde'
    ,'hsl(  0,75%,70%)' // 'Rosso'
    ,'hsl(180,55%,80%)' // 'Blu'
    ,'hsl(270,75%,70%)' // 'Viola'
    ,'hsl( 60,55%,80%)' // 'Giallo'
    ,'hsl(150,75%,70%)' // 'Verde'
    ,'hsl(240,55%,80%)' // 'Blu'
    ,'hsl(330,75%,70%)' // 'Viola'
    ,'hsl( 30,55%,80%)' // 'Arancione'
    
    ,'hsl( 30,75%,40%)' // 'Arancione'
    ,'hsl(120,75%,40%)' // 'Verde'
    ,'hsl(210,75%,40%)' // 'Blue'
    ,'hsl(300,75%,40%)' // 'Viola'
    ,'hsl( 90,75%,40%)' // 'Verde'
    ,'hsl(  0,75%,40%)' // 'Rosso'
    ,'hsl(180,75%,40%)' // 'Blu'
    ,'hsl(270,75%,40%)' // 'Viola'
    ,'hsl( 60,75%,40%)' // 'Giallo'
    ,'hsl(150,75%,40%)' // 'Verde'
    ,'hsl(240,75%,40%)' // 'Blu'
    ,'hsl(330,75%,40%)' // 'Viola'
];
/*
,'hsl(  0,100%,35%)' // 'Rosso scuro'
,'hsl( 30,100%,35%)' // 'Arancione scuro'
,'hsl( 60,100%,35%)' // 'Giallo scuro'
,'hsl( 90,100%,35%)' // 'Giallo scuro'
,'hsl(120,100%,35%)' // 'Verde scuro'
,'hsl(150,100%,35%)' // 'Verde scuro'
,'hsl(180,100%,35%)' // 'Verde scuro'
,'hsl(210,100%,35%)' // 'Verde scuro'
,'hsl(240,100%,35%)' // 'Blu scuro'
,'hsl(270,100%,35%)' // 'Blu scuro'
,'hsl(300,100%,35%)' // 'Viola scuro'
,'hsl(330,100%,35%)' // 'Viola scuro'

,'hsl(  0,50%,50%)' // 'Rosso scuro'
,'hsl( 30,50%,50%)' // 'Arancione scuro'
,'hsl( 60,50%,50%)' // 'Giallo scuro'
,'hsl( 90,50%,50%)' // 'Giallo scuro'
,'hsl(120,50%,50%)' // 'Verde scuro'
,'hsl(150,50%,50%)' // 'Verde scuro'
,'hsl(180,50%,50%)' // 'Verde scuro'
,'hsl(210,50%,50%)' // 'Verde scuro'
,'hsl(240,50%,50%)' // 'Blu scuro'
,'hsl(270,50%,50%)' // 'Blu scuro'
,'hsl(300,50%,50%)' // 'Viola scuro'
,'hsl(330,50%,50%)' // 'Viola scuro'
*/
/*
//  '#FFFF66' // 'Giallo chiaro'
    ,'hsl(  0,100%,70%)' // 'Rosso chiaro'
    ,'hsl(120, 34%,55%)' // 'Verde chiaro'
    ,'hsl(240,100%,70%)' // 'Blu chiaro'
    ,'hsl( 30,100%,70%)' // 'Arancione chiaro'
    ,'hsl(300, 34%,55%)' // 'Viola chiaro'
 
//  ,'#FFFF00' // 'Giallo'
//  ,'#FF0000' // 'Rosso'
    ,'hsl(120,100%,25%)' // 'Verde'
    ,'hsl(240,100%,50%)' // 'Blu'
    ,'hsl( 39,100%,50%)' // 'Arancione'
    ,'hsl(300,100%,25%)' // 'Viola'
*/
/*
     "#FFC0CB" // Rosa
    ,"#FF0000" // Rosso
    ,"#A52A2A" // Marrone
    ,"#FF00FF" // Fucsia
    ,"#800080" // Viola
    ,"#4B0082"      // Indaco
    ,"#0000FF" // Blu
    ,"#00FFFF" // Celeste
    ,"#40E0D0"      // Turchese
    ,"#00FF00" // Verde
    ,"#FFFF00" // Giallo
    ,"#FFA500" // Arancione
*/

const GRAPH_PIVOT_LIMIT = 12;

const CustomSymbolShape = ({
   x, y, size, fill
}) => (
    <rect
        x           = {x}
        y           = {y}
        fill        = {fill}
        strokeWidth = {2}
        stroke      = {'#000000'}
        width       = {size}
        height      = {size}
        style       = {{ pointerEvents: 'none' }}
    />
);

// se ci sono più misure ed è pivot, le misure verranno inserite in un menu a tendina e la prima viene selezionata
export default function Graph({ oGraphContent = {} , className }) {
    
    const
         [ bLoading    , set_bLoading   ] = useState(null)
        ,[ data        , set_data       ] = useState([])
        ,[ asX         , set_asX        ] = useState([])
        ,[ asY         , set_asY        ] = useState([])
        ,[ sChartType  , set_sChartType ] = useState('')
        ,[ oMinMax     , set_oMinMax    ] = useState({ nMin: 0 , nMax: 0 })
        ,[ sMeasure    , set_sMeasure   ] = useState( 
            ( ( oGraphContent.oMappingField || {} )[ oGraphContent.asElementY[0] || '' ] || {} ).description 
            || oGraphContent.asElementY[0] 
            || '' 
        )
        
        ,formatNum0dec  = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 0 })
        ,formatNum1dec  = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 1 })
        ,formatDate     = v => `${utils.formatDateTime(v, {
            input  : 'YYYY-MM-DDThh:mm:ss.SSSZ'
            ,output : 'ddd D MMM YYYY'
        })}`
        ,isLine         = ( sChartType === 'line' )
        ,isBar          = ( sChartType === 'bar'  )
        ,isPie          = ( sChartType === 'pie'  )
        
        ,MAXLABELLENGTH = 18
        ,nPieceLength   = ~~( ( MAXLABELLENGTH - 3 ) / 2 )
        // OLD VERSION
     // ,fsCompressText = (s) => {
     //     const s1 = ( ( s === 0 ? 0 : ( s || '' ) ) + '' );
     //     const s2 = s1.replaceAll(separator, separator.trim() ); 
     //     return ( s1.length < MAXLABELLENGTH ) ? s1 : ( s2.slice(0,nPieceLength) + '...' + s2.slice(-nPieceLength) );
     // }
        ,fsCompressText = (s) => {
            
            const sText = ( ( ( s === 0 ) ? '0' : ( s || '' ) ) + '' );
            if ( sText.length < MAXLABELLENGTH ) return sText;
            
            let
                 sStart      = '' ,sEnd      = ''
                ,nStartCount = 0  ,nEndCount = 0
                ,nLeft       = 0  ,nRight    = sText.length - 1
            ;
            
            // Esempio: sText = "  Questo è un testo molto lungo che deve essere compresso  "
            
            // Ciclo principale per comprimere il testo
            // Continua finché non abbiamo elaborato tutta la stringa (nLeft <= nRight)
            // e finché non abbiamo raccolto abbastanza caratteri sia all'inizio che alla fine
            while ( nLeft <= nRight && ( nStartCount < nPieceLength || nEndCount < nPieceLength ) ) {
                
                // Gestione parte iniziale
                if ( nStartCount < nPieceLength ) {
                    
                    // Se il carattere corrente non è uno spazio iniziale o se abbiamo già iniziato a raccogliere caratteri...
                    if ( sText[ nLeft ] !== separator || sStart.length > 0 ) {
                        
                        // ...aggiungiamo il carattere a sStart
                        sStart += sText[ nLeft ];
                        // Incrementiamo il contatore dei caratteri raccolti all'inizio
                        nStartCount++;
                        
                    }
                    
                    // Passiamo al carattere successivo da sinistra
                    nLeft++;
                }
                
                // Gestione parte finale
                // Verifichiamo anche che nLeft <= nRight per evitare di processare due volte lo stesso carattere
                if ( nEndCount < nPieceLength && nLeft <= nRight ) {
                    
                    // Se il carattere corrente non è uno spazio finale o se abbiamo già iniziato a raccogliere caratteri...
                    if ( sText[ nRight ] !== separator || sEnd.length > 0 ) {
                        
                        // ...aggiungiamo il carattere all'inizio di sEnd (per mantenere l'ordine corretto)
                        sEnd = sText[ nRight ] + sEnd;
                        // Incrementiamo il contatore dei caratteri raccolti alla fine
                        nEndCount++;
                        
                    }
                    
                    // Passiamo al carattere precedente da destra
                    nRight--;
                }
                
                // Esempio di come evolve il processo:
                // Iterazione 1: sStart = "Q",          sEnd = "o"       (assumendo che il testo inizi con "Q" e finisca con "o")
                // Iterazione 2: sStart = "Qu",         sEnd = "so"
                // ...
                // Iterazione 20: sStart = "Questo è un testo m", sEnd = "essere compresso"
            }
            
            // Esempio dopo il ciclo:
            // sStart = "Questo è un testo m"
            // sEnd = "essere compresso"
            
            // Rimuovi eventuali spazi residui all'inizio e alla fine
            sStart = sStart.trimEnd();
            sEnd   = sEnd.trimStart();
            
            // Esempio risultato finale:
            // "Questo è un testo m...essere compresso"
            
            // Aggiungi i puntini di sospensione solo se entrambe le parti non sono vuote
            return sStart + ( sStart && sEnd ? '...' : '' ) + sEnd;
        }
    ;
    
    
    const fTooltipFormat = ( v, sMea ) => {
        
        const
             xIndexValue    = ((isLine ? v.point.data.xFormatted :
                                isBar  ? v.indexValue            :
                                isPie  ? v.datum.label           : '' ) || '' ) + ''
            
            ,color          = ((isLine ? v.point.serieColor      :
                                isBar  ? v.color                 :
                                isPie  ? v.datum.color           : '' ) || '' ) + ''
            
            ,id             = ((isLine ? v.point.serieId         :
                                isBar  ? v.id                    :
                                isPie  ? sMea                    : '' ) || '' ) + ''
            
            ,value          = ( isLine ? v.point.data.yFormatted :
                                isBar  ? ( ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec )(v.value) :
                                isPie  ? ( ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec )(v.datum.value)  : 0 ) || 0
            
            ,as_xFormatted  = ( typeof xIndexValue === 'string' ? xIndexValue : '' ).split(separator)
        ;
        
        return <table className="graph-tooltip">
            <tbody>
            {
                asX[0].split(separator).map(
                    ( sElementX, n ) => <tr key={sElementX+n} className="graph-tooltip-row">
                        <td></td><td>{ sElementX }:</td><td className="graph-tooltip-value">{ as_xFormatted[n] }</td>
                    </tr>
                )
            }
            <tr className="graph-tooltip-row"><td><div style={{ border:"1px solid #555555" ,backgroundColor: color ,width:"10px" ,height:"10px" }}></div></td><td>{ id }:</td><td className="graph-tooltip-value">{ value }</td></tr>
            </tbody>
        </table>
    }
    
    
    const legends = [
        {
             dataFrom           : 'keys'
            ,anchor             : 'top-right'
            ,direction          : 'column'
            ,justify            : false
            ,translateX         : 80
            ,translateY         : -50
            ,itemsSpacing       : 0
            ,itemDirection      : 'right-to-left'
            ,itemWidth          : 80
            ,itemHeight         : 20
            ,itemOpacity        : 1
            ,symbolSize         : 12
            ,symbolShape        : CustomSymbolShape
            ,toggleSerie        : true
            ,reverse            : false
        }
    ];
    const margins = { top: 100, right: 100, bottom: 100, left: 100 };
    
    useEffect( () => {
        set_sChartType(oGraphContent.sChartType);
    }, [oGraphContent]);
    
    
    /*
    const oFieldsMap = oGraphContent.oFieldsMap;        // es. { 'DATA_RIF': v => moment(v,'YYYYMMDD').format('ddd D MMM YYYY') }
    if ( oFieldsMap ) {
        aoValues = aoValues.map( oRow => Object.keys(oRow).reduce( ( o, sKey ) => ({ ...o, [sKey]: oFieldsMap[sKey]( oRow[sKey] ) }) ,{}) )
    }
    */
    
    useEffect(() => {
        
            let
                 aoRows         = [ ...( oGraphContent.aoRows        || [] ) ]
                ,oDrillDown     = { ...( oGraphContent.oDrillDown    || {} ) }
                ,asElementX     = [ ...( oGraphContent.asElementX    || [] ) ] // dimensioni es. [ 'DATA_RIF' ]
                ,asElementY     = [ ...( oGraphContent.asElementY    || [] ) ] // se è pivottato sono le pivot, altrimenti sono le misure
                ,asElementZ     = [ ...( oGraphContent.asElementZ    || [] ) ] // dimensioni pivottate es. [ 'YEAR', 'MONTH' ]
                ,oMappingField  = { ...( oGraphContent.oMappingField || {} ) }
                ,oGrandTotal    = {}
                ,aoValues
            ;
            
            if ( oGraphContent.excludeTOT ) {
                oGrandTotal     = aoRows.shift(); // rimuovo il primo record (il GRAND TOTAL)
                // TODO visualizzare il GRAND TOTAL da qualche parte
            }
            /* ATTENZIONE VENGONO GIA' PASSATI TAGLIATI A QUESTO COMPONENTE !!! 
            const nFirstIndexPositive   = aoRows.findIndex( o => o.PROG_ID > 0 );
            
            aoRows = aoRows.slice( nFirstIndexPositive );
            aoRows = aoRows.slice(0,100); // solo i primi 100 valori
            */
            // se è stato specificato oMappingField uso convertDataType per trasformare il valore (della relativa dimensione o misura) da mostrare
            if ( oMappingField ) {
                
                aoRows     = aoRows.map( oRow => Object.keys(oRow).reduce(
                    ( oRowModified, sKey ) =>
                        ({
                            ...oRowModified
                            ,[ ( oMappingField[sKey] || {} ).description || sKey ]: // mappo il campo database con la relativa description
                            // converto il valore associato a quel campo in base al tipo indicato in filterDataType
                                utils.convertDataType( oRow[sKey], ( oMappingField[sKey] || {} ).filterDataType || sKey )
                        })
                    ,{}
                ) );
                
                const mappingElement = sElement => ( oMappingField[sElement] || {} ).description || sElement;
                asElementX = asElementX.map( mappingElement );
                asElementY = asElementY.map( mappingElement );
                asElementZ = asElementZ.map( mappingElement );
                oDrillDown = Object.keys(oDrillDown).reduce( ( o, k ) => ({
                     ...o
                    ,[mappingElement(k)]: (
                        oDrillDown[k].includes(RANGES_SEPARATOR) ? oDrillDown[k].split(RANGES_SEPARATOR)[0] : oDrillDown[k]
                    )
                }), {});
                
            }
            bDebug && console.log('asElementX', asElementX);
            bDebug && console.log('asElementY', asElementY);
            bDebug && console.log('asElementZ', asElementZ);
            
            // bDebug && console.table(aoRows);
            
            // in caso di tabular con più dimensioni concateno i valori delle varie dimensioni in una sola stringa
            if ( asElementX.length > 1 ) {
                const sNewKey   = asElementX.join(separator);
                aoRows          = aoRows.map( oRow => ({
                    ...oRow
                    ,[sNewKey]: asElementX.map( sElementX => ( oRow[sElementX] || oDrillDown[sElementX] || '' ) ).join(separator)
                }) );
                asElementX      = [ sNewKey ];
            }
            
            // ottengo i numeri minimi e massimi da rappresentare (in generale, tenendo conto di tutte le misure)
            let nMin = 0, nMax = 0;
            for ( const oRow of aoRows ) {
                for ( const sY of ( asElementZ.length ? [sMeasure] : asElementY ) ) {
                    nMin = Math.min( ( nMin || 0 ), oRow[sY] || 0 );
                    nMax = Math.max( ( nMax || 0 ), oRow[sY] || 0 );
                }
            }
            set_oMinMax({ nMin, nMax });
            
            bDebug && console.log('sChartType: ',sChartType);
            
            if ( isLine ||  isBar ) {
                /* risultato finale:
                    [
                        {
                             "id"    : "japan"
                            ,"data"  : [
                                          { "x": "plane"         ,"y": 91 }
                                         ,{ "x": "helicopter"    ,"y": 85 }
                                       ]
                        }
                    ]
                */
                
                // [ 'SPOT_YEAR 2020' ]: { PROG_ID: 1 ,CHANNEL_DESC: 'CNN' ,SPOT_YEAR: '2020' ,SPOT_LENGTH: 213456 }
                
                const sElementX = asElementX[0];
                
                // solo in caso PIVOT
                if ( asElementZ && asElementZ.length ) {
                    
                    asElementY = []; // resetto l'array (che conteneva i campi delle misure)
                                     // per poi riempirlo con i valori del record dei campi pivot
                    
                    let
                         oRecordPrec         = {}
                        ,aoRowsPivoted       = []
                        ,nRowsPivotedCounter = -1
                    ;
                    
                    // per ogni record andrò a riempire aoRowsPivoted
                    // (A) aggiungo un elemento in più a aoRowsPivoted se cambia il PROG_ID o (B) aggiungo campi a parità di PROG_ID
                    for ( const oRecord of aoRows ) {
                        
                        // (A) aggiungo un elemento in più a aoRowsPivoted se cambia il PROG_ID dei record che sto scorrendo
                        if ( oRecord.PROG_ID !== oRecordPrec.PROG_ID ) {
                            oRecordPrec = { ...oRecord };
                            nRowsPivotedCounter++;
                        }
                        
                        let asLegendKey = [];
                        
                        // per ogni dimensioni PIVOT
                        for ( const sElementZ of asElementZ ) {
                            asLegendKey.push( oRecord[ sElementZ ] );
                            delete oRecord[ sElementZ ];
                        }
                        
                        const sKey = asLegendKey.join(separator);
                        
                        // vado a sovrascrivere sempre lo stesso record in aoRowsPivoted e (B) aggiungo campi a parità di PROG_ID
                        aoRowsPivoted[ nRowsPivotedCounter ] = {
                            ...( aoRowsPivoted[ nRowsPivotedCounter ] || oRecord || {} )      // se stesso se esiste
                            ,[ sKey ] : oRecord[ sMeasure ]  // [ 'SPOT_YEAR 2020' ]: 213456
                        };
                        
                        // non è efficiente ma serve per preservare l'ordine originale
                        if ( !asElementY.includes(sKey) ) {
                            asElementY.push(sKey);
                        }
                        
                    }
                    
                    asElementY      = asElementY.slice( 0, GRAPH_PIVOT_LIMIT );
                    
                    if ( isBar ) {
                        
                        /* esempio bar
                            [
                                {
                                     "country"  : "AD"
                                     
                                    ,"burger"   : 132
                                    ,"sandwich" : 125
                                    ,"kebab"    : 256
                                }
                            ]
                        */
                        
                        aoValues        = aoRowsPivoted;
                        asElementZ      = [];
                        
                    } else if ( isLine ) {
                        
                        aoValues        = asElementY.reverse().map(
                            sElementY => ({
                                 id:    sElementY
                             // ,color: 'red'
                                ,data:  aoRowsPivoted.map( oRow => ({ x: oRow[sElementX] || '-' ,y: oRow[sElementY] || 0 }) )
                            })
                        );
                        
                    }
                    
                    
                } else {
                    // CASO NON PIVOT
                    
                    if ( isBar ) {
                        
                        /* esempio bar
                            [
                                {
                                     "country"  : "AD"
                                     
                                    ,"burger"   : 132
                                    ,"sandwich" : 125
                                    ,"kebab"    : 256
                                }
                            ]
                        */
                        
                        aoValues        = aoRows;
                        
                    } else if ( isLine ) {
                        
                        aoValues        = asElementY.reverse().map(
                            sElementY => ({
                                 id:    sElementY
                             // ,color: 'red'
                                ,data:  aoRows.map( oRow => ({ x: oRow[sElementX] || '-' ,y: oRow[sElementY] || 0 }) )
                            })
                        );
                        
                    }
                    
                } // CASO NON PIVOT
                
            } else
                // il grafico a torta è SOLO NON PIVOT
            if ( isPie ) {
                
                /* esempio pie
                    [
                        {
                             "id"   : "python"
                            ,"label": "python"
                            ,"value": 90
                        }
                    ]
                */
                
                // const oFieldsMap = oGraphContent.oFieldsMap;        // es. { 'DATA_RIF': v => moment(v,'YYYYMMDD').format('ddd D MMM YYYY') }
                aoValues = aoRows.map( oRow => ({ id: oRow[asElementX[0]] ,label: oRow[asElementX[0]] ,value: oRow[sMeasure] }) );
                
            }
            
         // bDebug && console.log('aoValues'  , aoValues  );
            bDebug && console.log('asElementX', asElementX);
            bDebug && console.log('asElementY', asElementY);
            bDebug && console.log('asElementZ', asElementZ);
            
            set_asX(    [...(asElementX || []) ]);
            set_asY(    [...(asElementY || []) ]);
            set_data(   [...(aoValues   || []) ]);
        
            set_bLoading(true);
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sChartType, sMeasure ]);
    
    const asLimitedColors = asColors.slice( 0, asY.length );
    
    return bLoading && <div style={{ height: 'calc( 100vh - 168px )' }} className={ 'graph ' + className }>{
        // se ci sono più misure ed è pivot, le misure verranno inserite in un menu a tendina e la prima viene selezionata
        ( oGraphContent.asElementY.length > 1 ) && ( oGraphContent.asElementZ || isPie ) && <FormControl id="selectMeasureContainer">
            <InputLabel id="selectMeasureLabel" htmlFor="selectMeasure">Measure</InputLabel>
            <Select
                labelId  = "selectMeasureLabel"
                id       = "selectMeasure"
                value    = { sMeasure }
                onChange = { (event) => {
                    set_bLoading(false);
                    set_sMeasure(event.target.value);
                }}
            >
                {
                    oGraphContent.asElementY.map( sMeasureY => {
                        sMeasureY = ( ( oGraphContent.oMappingField || {} )[sMeasureY] || {} ).description || sMeasureY;
                        return <MenuItem key={sMeasureY || ''} value={sMeasureY || ''}>{sMeasureY || ''}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    }{  
        
        // solo se non è a torta E è pivot
        !isPie && oGraphContent.asElementZ && <div id="legendLabel"><span>{
            ( ( asY.length === GRAPH_PIVOT_LIMIT ) ? '(Limited displayed entries)' : '' )
        }</span><span>{
            ( oGraphContent.asElementZ || oGraphContent.asElementY )
                .map(  o => ( ( ( oGraphContent.oMappingField || {} )[ o || '' ] || {} ).description || o || '' ) )
                .join( ' ' + separator + ' ' )
        }</span></div>
        
    }{
        
        // solo se non è a torta
        !isPie && <div id="legendX"><span>{
            asX.map( s => s.replace( new RegExp(separator, 'g'), ' ' + separator + ' ' ) )
        }</span>
        <span>{
            ( ( asY.length === GRAPH_PIVOT_LIMIT ) ? '(Limited displayed rows)' : '' )
        }</span>
        </div>
        
    }{
        
        ( data != null ) && ( data.length > 0 ) && (
            
            ( isBar ) ? (
                
                <ResponsiveBar
                    data                    ={data}
                    keys                    ={ asY /* qui ci vanno l'elenco di etichette da visualizzare in legenda */ }
                    indexBy                 ={ asX[0] || null /* è il campo i cui valori verranno visualizzati nell'asse X */ }
                    margin                  ={margins}
                    padding                 ={0.35}
                    innerPadding            ={2}
                    groupMode               ="grouped"
                    valueScale              ={{ type:   'linear' }}
                    indexScale              ={{ type:   'band'   , round: true }}
                    colors                  ={ asLimitedColors /* versione barre colorate: ( asY.length < 2 ) ? ({index}) => ( asColors[ index % asColors.length ] ) : asLimitedColors */ }
                    borderWidth             ={0.5}
                    borderColor             ={{ from:   'color'  , modifiers: [ [ 'darker', 0.5 ] ] }}
                    axisTop                 ={null}
                    axisRight               ={null}
                    axisBottom              ={{
                         tickSize        : 5
                        ,tickPadding     : 5
                        ,tickRotation    : 45
                        ,format          : fsCompressText
                    }}
                    axisLeft                ={{
                         tickSize        : 5
                        ,tickPadding     : 5
                        ,tickRotation    : 0
                        ,format          : ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec
                    }}
                    labelSkipWidth          ={12}
                    labelSkipHeight         ={12}
                    labelTextColor          ={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends                 ={legends}
                    animate                 ={false}
                    motionStiffness         ={90}
                    motionDamping           ={15}
                    enableLabel             ={false}
                    tooltip                 ={fTooltipFormat}
                    minValue                ={oMinMax.nMin}
                    maxValue                ={oMinMax.nMax}
                />
            
            ) : ( isLine ) ? (
                // sliceTooltip    ={ v => `${ v.slice.points[0].data.xFormatted } : ${ v.slice.points[0].serieId } ${ v.slice.points[0].data.yFormatted }` }
                // enableSlices    ={'x'}
                <ResponsiveLine
                    data                    ={data}
                    margin                  ={margins}
                    enableGridX             ={false}
                    animate                 ={false}
                    xScale                  ={{ type: 'point' }}
                    xFormat                 ={ v => v }
                    tooltip                 ={fTooltipFormat}
                    yScale                  ={{
                         type           : 'linear'
                        ,min            : oMinMax.nMin
                        ,max            : oMinMax.nMax
                        ,stacked        : false
                        ,reverse        : false
                    }}
                    yFormat                 ={ ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec }
                    axisTop                 ={null}
                    axisRight               ={null}
                    axisBottom              ={{
                         orient         : 'bottom'
                        ,tickSize       : 5
                        ,tickPadding    : 5
                        ,tickRotation   : 45
                        ,format         : fsCompressText
                    }}
                    axisLeft                ={{
                         orient         : 'left'
                        ,tickSize       : 5
                        ,tickPadding    : 5
                        ,tickRotation   : 0
                        ,format         : ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec
                    }}
                    pointSize               ={3}
                    pointColor              ={ 'white' }
                    pointBorderWidth        ={1}
                    pointBorderColor        ={'black'}
                    pointLabel              ={ d => `${d.x} : ${d.y}`}
                    useMesh                 ={true}
                    legends                 ={legends}
                    enablePointLabel        ={false}
                    pointLabelYOffset       ={0}
                    enableArea              ={false}
                    areaOpacity             ={0.8}
                    colors                  ={ asLimitedColors.reverse() }
                
                />
            
            ) : ( isPie ) ? (
                
                <ResponsivePie
                    data                    ={data}
                    margin                  ={ { top: 80, right: 0, bottom: 60, left: 0 } }
                    padAngle                ={0}
                    cornerRadius            ={5}
                    colors                  ={{ scheme: 'set3' }}
                    borderWidth             ={0}
                    borderColor             ={{from: 'color', modifiers: [['darker', 0.2]]}}
                    valueFormat             ={formatNum0dec}
                    enableSliceLabels       ={false}
                    sliceLabelsSkipAngle    ={10}
                    sliceLabelsTextColor    ="#333333"
                    innerRadius             ={0.3}
                    animate                 ={false}
                    enableArcLabels         ={false}
                    arcLabelsSkipAngle      ={18.1}
                    arcLabelsRadiusOffset   ={0.65}
                    arcLinkLabelsSkipAngle  ={6}
                    arcLinkLabel            ={ v => v.id }
                    tooltip                 ={ v => fTooltipFormat( v, sMeasure ) }
                />
            
            ) : <></>
        
        )
        
    }</div>
    
}

//                     arcLabel                ={ v => v.data.perc }
//                     tooltip                 ={ v => v.datum }

