import React, {
    useEffect,
    useState
}                           from 'react';
import { useHistory }       from 'react-router';
import { useLocation }      from 'react-router-dom';
import                      '../css/Main.css';
import clsx                 from 'clsx';
import {
    makeStyles,
    createTheme,
    MuiThemeProvider
}                           from '@material-ui/core/styles';

import CssBaseline          from '@material-ui/core/CssBaseline';
import AppBar               from '@material-ui/core/AppBar';
import Toolbar              from '@material-ui/core/Toolbar';
import Typography           from '@material-ui/core/Typography';
import IconButton           from '@material-ui/core/IconButton';
import Container            from '@material-ui/core/Container';
import Menu                 from '@material-ui/core/Menu';
import MenuItem             from '@material-ui/core/MenuItem';
import Tooltip              from '@material-ui/core/Tooltip';
import Divider              from '@material-ui/core/Divider';

// --- icone ---
import PersonIcon           from '@material-ui/icons/Person';
import PersonOutlineIcon    from '@material-ui/icons/PersonOutline';
import ExitToAppIcon        from '@material-ui/icons/ExitToApp';
import PeopleIcon           from '@material-ui/icons/People';
import PeopleOutlineIcon    from '@material-ui/icons/PeopleOutline';
import SettingsIcon         from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CachedIcon           from '@material-ui/icons/Cached';
import ZoomInIcon           from '@material-ui/icons/ZoomIn';
import ZoomOutIcon          from '@material-ui/icons/ZoomOut';
import FullscreenIcon       from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon   from '@material-ui/icons/FullscreenExit';
import AttachMoneyIcon      from '@material-ui/icons/AttachMoney';
import VpnKeyIcon           from '@material-ui/icons/VpnKey';
import CreditCardIcon       from '@material-ui/icons/CreditCard';
import AccountBoxIcon       from '@material-ui/icons/AccountBox';
import NotificationsIcon    from '@material-ui/icons/Notifications';
import NotificationsNewIcon from '@material-ui/icons/NotificationsActive';
import NoNotificationsIcon  from '@material-ui/icons/NotificationsNone';
import CheckCircleIcon        from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// --- componenti del progetto ---
import { useAuth }          from '../context/auth';
import { Loading }          from './loading';
import SideMenu             from './SideMenu';
import FetchService         from '../common/FetchService';
import Utils                from '../common/CommonUtilities';
import { SnackAlert }       from '../common/SnackAlert';
import { PopupConfirm }     from '../common/PopupConfirm';
import Dashboard            from './dashboard/Dashboard';
import CubeBuilder          from './cubes/CubeBuilder';
import ChangePassword       from './profile/ChangePassword';
import ImpersonateUser      from './profile/ImpersonateUser';
import Groups               from './settings/UserGroups';
import CubesSettings        from './settings/CubesSettings';
import UsersSettings        from './settings/UsersSettings';
import UsersEditing         from './settings/UsersEditing';
import FormGroup            from '@material-ui/core/FormGroup';
import FormControlLabel     from '@material-ui/core/FormControlLabel';
import {Switch}             from '@material-ui/core';
import config               from '../config';

const theme = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
        primary: {
            main: '#519657'
        },
        secondary: {
            main: 'rgb(33, 33, 33)'
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
    }
});

const debug = false;
const defaultDrawerOpenedWidth = 240;
const defaultDrawerClosedWidth = 72;
const isNotificationsEnabled = config.SQS_ENABLED === 'Y';

export default function Main() {

    const
         [ cubeCod          ,setCubeCod         ] = useState(0)             // cubo selezionato
        ,[ layoutDesc       ,setLayoutDesc      ] = useState('')            // layout selezionato
        ,[ layoutTitle      ,setLayoutTitle     ] = useState('')            // titolo layout da visualizzare in alto
        ,[ menuSelected     ,setMenuSelected    ] = useState('dashboard')   // menu selezionato
        ,[ routeTitle       ,setRouteTitle      ] = useState('')            // titolo della pagina
        ,[ cubeDateTime     ,setCubeDateTime    ] = useState('')            // data e ora di ultimo rebuild del cubo
        ,[ cubeNotes        ,setCubeNotes       ] = useState('')            // note del cubo
        ,[ maxTabDim        ,setMaxTabDim       ] = useState(5)             // numero massimo dimensioni selezionabili in tabular mode

        ,[ cubes            ,loadCubes          ] = useState([])            // elenco dei cubi
        ,[ anchorEl         ,setAnchorEl        ] = useState(null)          // menu utente ("Impersonate User", "Change Password", "Logout")
        ,[ anchorSettings   ,setAnchorSettings  ] = useState(null)          // settings ("User Groups")
        ,[ anchorNotify     ,setAnchorNotify    ] = useState(null)
        ,[                  ,setMenuSettings    ] = useState(false)         // settings ("User Groups")
        ,[ drawerOpen       ,setDrawerOpen      ] = useState(true)          // stato del drawer ( aperto / chiuso )
        ,[ drawerWidth      ,setDrawerWidth     ] = useState( defaultDrawerOpenedWidth ) // larghezza del drawer
        ,[ tabSelected      ,setTabSelected     ] = useState('menu')        // tab laterale selezionato
        ,[ locationKeys     ,setLocationKeys    ] = useState([])            // per la gestione dei pulsanti "back" e "forward" del browser
        ,[ bFullScreen      ,setbFullScreen     ] = useState(!!document.fullscreenElement)
        ,[ nZoom            ,setnZoom           ] = useState(0)
        ,[ oResRefreshUsers ,setoResRefreshUsers] = useState({ nRetVal: NaN, vOutputRowcounts: '' })
        ,[ oResRebuildPort  ,setoResRebuildPort ] = useState({ nRetVal: NaN, vOutputRowcounts: '' })
        ,[ oNotifyOptions   ,set_oNotifyOptions ] = useState(null)
        ,[ bReloadUsers     ,setbReloadUsers    ] = useState(false)
        ,[ bReloadCubes  /* ,setbReloadCubes */ ] = useState(false)
        ,[ isLoading        ,set_isLoading      ] = useState(false)
        ,[ sPopupType       ,set_sPopupType     ] = useState('')
        ,[ openChangePsw    ,set_openChangePsw  ] = useState(false)
        ,[ oLicenses        ,set_oLicenses      ] = useState(null)
        ,[ bUsePortfolio    ,set_bUsePortfolio  ] = useState(true)
        ,[ aoNotifications  ,set_aoNotifications] = useState([]) // [{ id: 1,  message: 'this is a notification', viewed: false }]
    ;


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            height: '100%',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
            backgroundColor: '#519657'
        },
        toolbarIcon: {
            backgroundColor: '#f5f5f6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            /* transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),*/
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            /* transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),*/
        },
        title: {
            flexGrow: 1,
            textAlign: 'left'
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            width: `calc(100% - ${drawerWidth}px)`,
            minWidth: '700px',
        }
    }));
    const classes = useStyles();
    const { setAuthTokens, authPrivileges, authKUser, authFullName } = useAuth();
    const originalKUser = localStorage.getItem('originalKUser');
    const authPrivilegesParsed = JSON.parse(authPrivileges) || {};
    const isAdmin = authPrivilegesParsed.FLAG_ADMIN === 'Y';
    const isDual  = authPrivilegesParsed.FLAG_DUAL_PROFILE === 'Y';
    const isSelf  = +authKUser === +originalKUser;

    // const sCustomer = ( localStorage.getItem('appid') || process.env.REACT_APP_ENV || 'undefined' ).toLowerCase();
    // const isClear   = sCustomer.includes('clear');
    // const isCairo   = sCustomer.includes('cairo');
    const FLAG_UPDATE_USERS      = localStorage.getItem( 'FLAG_UPDATE_USERS');
    const FLAG_PORTFOLIO_REBUILD = localStorage.getItem( 'FLAG_PORTFOLIO_REBUILD');

    let history         = useHistory();
    const { pathname }  = useLocation();

    /* resize page
    const adjustDrawerBasedOnWindowWidth = () => {
        setDrawerOpen( window.innerWidth >= 798 );
    }; */

    // window.addEventListener( 'resize', adjustDrawerBasedOnWindowWidth );

    // per la gestione dei pulsanti "back" e "forward" del browser
    useEffect( () => {
        return history.listen( location => {

            if ( history.action === 'PUSH' ) {
                setLocationKeys([ location.key ])
            }

            if ( history.action === 'POP' ) {
                if ( locationKeys[1] === location.key ) {
                    setLocationKeys(([ _, ...keys ]) => keys)
                    // Handle forward event
                } else {
                    setLocationKeys(( keys) => [ location.key, ...keys ] )
                    // Handle back event
                    setTabSelected('menu'); // per risolvere bug di riselezione del tab quando viene cliccato il pulsante back del browser
                }
            }

        });
    }, [ locationKeys, history ]);

    useEffect( () => {
        // console.log('pathname: ' + pathname);
        if ( pathname === '/settings/groups' ) {
            setMenuSettings(true);
        }
    }, [ pathname ]);

    useEffect( () => {
        // adjustDrawerBasedOnWindowWidth();
        setDrawerWidth( drawerOpen ? defaultDrawerOpenedWidth : defaultDrawerClosedWidth );
    }, [ drawerOpen ]);

    useEffect( () => {

        const initCubes = async () => {
            debug && console.log('--- Main (cambio di stato) --- rilanciata get-cubes');
            const cubes = await FetchService.asyncGet({
                url: '/settings/get-cubes',
                displayErrorHandler: error => {
                    console.error(error);
                    set_oNotifyOptions({ severity: 'error', message: 'Error while loading cubes' });
                }
            }) || [];
            loadCubes(cubes);
        }
        initCubes();

    }, [ pathname, authKUser ]); // al cambio utente e al cambio pathname

    useEffect( () => {
        // console.log('cubeCod attuale: ' + cubeCod);
    }, [ cubeCod ]);

    useEffect( () => {
        // console.log('cubes: ' + cubes.map( c => c.CUBE_DESC ).join(', '));
        if ( cubes.length > 0 ) {

            if ( pathname.startsWith('/cubes/') ) {

                const sCubeDesc    = pathname.split('/')[2];
                const objCube      = cubes.find( oCube => oCube.CUBE_DESC.toLowerCase() === sCubeDesc );
                if ( objCube ) {
                    setMenuSelected   (`cube-${sCubeDesc}`              );
                    setCubeCod        (objCube.CUBE_COD                 );
                    setRouteTitle     (objCube.CUBE_DESC                );
                    setCubeDateTime   (objCube.LAST_REBUILD_DATE        );
                    setCubeNotes      ( objCube.NOTES ? ( ' - ' + objCube.NOTES ) : '' );
                    setMaxTabDim      (+objCube.MAX_TABULAR_DIMENSIONS > 1 ? objCube.MAX_TABULAR_DIMENSIONS : 5 );
                } else {
                    console.error( 'cube "' + sCubeDesc + '" NON presente in cubes');
                }

            }

        }
    }, [ pathname, cubes ]);

    useEffect( () => {

        // init menu
        if ( ( !cubes.length ) || !pathname.startsWith('/cubes/') ) {
            setCubeCod(0); // fix per forzare ricaricamento nel caso di cambio pagina, come se fosse cambiato il cubo
            setCubeDateTime  ('');
            setCubeNotes('');
            setLayoutTitle('');
            switch (pathname) {
                case '/dashboard':
                    setMenuSelected  ('dashboard');
                    setRouteTitle    ('Dashboard');
                    break;
                case '/settings/user-groups':
                    setMenuSelected  ('user-groups');
                    setRouteTitle    ('User groups');
                    break;
                case '/settings/cubes-settings':
                    setMenuSelected  ('cubes-settings');
                    setRouteTitle    ('Cubes Settings');
                    break;
                case '/settings/users-settings':
                    setMenuSelected  ('users-settings');
                    setRouteTitle    ('Users Settings');
                    break;
                case '/settings/users-editing':
                    setMenuSelected  ('users-editing');
                    setRouteTitle    ('Users Settings');
                    break;
                case '/change-password':
                    setMenuSelected  ('change-password');
                    setRouteTitle    ('Change Password');
                    break;
                case '/impersonate-user':
                    setMenuSelected  ('impersonate-user');
                    setRouteTitle    ('Impersonate User');
                    break;
                default:
                    history.push('/dashboard');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pathname ]);

    useEffect( () => {
        if ( ( cubes.length > 0 ) && pathname.startsWith('/cubes/') ) {
            const sCubeDesc    = pathname.split('/')[2];
            const objCube      = cubes.find( oCube => oCube.CUBE_DESC.toLowerCase() === sCubeDesc );
            if ( objCube ) {
                setLayoutTitle( layoutDesc ? '[' + layoutDesc + ']' : '' );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ layoutDesc ]);


    function menuItemClicked( sMenuItemClicked ) {
        if ( sMenuItemClicked === 'dashboard' ) {
            history.push('/dashboard');
        } else {
            const sCubeDesc = sMenuItemClicked.toLowerCase();
            history.push(`/cubes/${sCubeDesc}`);
        }
    }

    function gotoUserGroups(){
        setTabSelected('menu');
        if(pathname !== '/settings/user-groups') {
            history.push(`/settings/user-groups`);
        }
        handleCloseMenuSettings();
    }

    const gotoCubesSettings = () => {
        setTabSelected('menu');
        if (pathname !== '/settings/cubes-settings') {
            history.push(`/settings/cubes-settings`);
        }
        handleCloseMenuSettings();
    }
    /*
    const gotoUsersSettings = () => {
        setTabSelected('menu');
        if (pathname !== '/settings/users-settings') {
            history.push(`/settings/users-settings`);
        }
        handleCloseMenuSettings();
    }
    */
    const gotoUsersEditing = () => {
        setTabSelected('menu');
        if (pathname !== '/settings/users-editing') {
            history.push(`/settings/users-editing`);
        }
        handleCloseMenuSettings();
    }

    function impersonateUser() {
        setTabSelected('menu');
        if(pathname !== '/impersonate-user') {
            history.push('/impersonate-user');
        }
        handleCloseMenu();
    }

    async function endImpersonateUser() {

        setTabSelected('menu');

        let responseRetVal = await FetchService.asyncPost({
            url: '/end-impersonate',
            params: { kUser: originalKUser },
            displayErrorHandler: (error) => {
                console.error(error);
                logOut();
            }
        });
        if ( responseRetVal ) {
            setAuthTokens(responseRetVal);
            history.push('/dashboard');
        }

    }
    
    async function getLicenses() {
        
        setTabSelected('menu');
        
        let aoLicenses = await FetchService.asyncGet({
            url: '/settings/get-licenses',
            displayErrorHandler: (error) => {
                console.error(error);
                logOut();
            }
        }) || [];
        set_oLicenses(( aoLicenses && aoLicenses.length ) ? aoLicenses[0] : {});
        
    } 

    function logOut() {
        setAuthTokens();
    }

    function userImpersonate(data){
        setAuthTokens(data);
        history.push('/dashboard');
    }

    function changePassword() {
        handleCloseMenu();
        set_openChangePsw(true);
    }

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickMenuSettings = (event) => {
        setAnchorSettings(event.currentTarget);
    };

    const handleCloseMenuSettings = () => {
        setAnchorSettings(null);
    };
    
    const handleClickMenuNotifications = (event) => {
        setAnchorNotify(event.currentTarget);
    };
    
    const handleCloseMenuNotifications = () => {
        setAnchorNotify(null);
    };

    const toggleFullScreen = () => {
        // make the element go to full-screen mode
        if ( !!document.fullscreenElement ) {
            document.exitFullscreen();
            setbFullScreen(false);
        } else {
            document.getElementsByTagName('body')[0].requestFullscreen()
                /*
                .then(function() {
                    // element has entered fullscreen mode successfully
                })
                .catch(function(error) {
                    // element could not enter fullscreen mode
                });
                */
            setbFullScreen(true);
        }
    }

    const zoomIn = () => {
        if ( nZoom < 3 ) {
            setnZoom( nZoom + 1 );
        }
    }

    const zoomOut = () => {
        if ( nZoom > -3 ) {
            setnZoom( nZoom - 1 );
        }
    }

    const refreshUsers = () => {
        FetchService.asyncGet({
            url: '/settings/refresh-users',
            displayErrorHandler: error => {
                console.error(error);
                set_oNotifyOptions({ severity: 'error', message: 'Users update failed' });
            }
        }).then( setoResRefreshUsers );
    }

    const rebuildPortfolio = () => {
        FetchService.asyncGet({
            url: '/settings/rebuild-portfolio',
            displayErrorHandler: error => {
                console.error(error);
                set_oNotifyOptions({ severity: 'error', message: 'Portfolio update failed' });
            }
        }).then( setoResRebuildPort );
    }

    const handleCloseNotifica = () => { // reset
        setoResRefreshUsers({ nRetVal: NaN, vOutputRowcounts: '' });
        setoResRebuildPort( { nRetVal: NaN, vOutputRowcounts: '' });
        set_oNotifyOptions(null)
    };

    const createPopupDialog = () => {

        const oPopupOptions = {
            'refreshUsers': {
                 DialogTitle:       'Update Users'
                ,DialogContentText: 'Are you sure you want to update Users?'
                ,onClick:           refreshUsers
                ,firstButtonLabel:  'confirm'
            },
            'rebuildPortfolio': {
                 DialogTitle:       'Update Portfolio'
                ,DialogContentText: 'Are you sure you want to update Portfolio?'
                ,onClick:           rebuildPortfolio
                ,firstButtonLabel:  'confirm'
            },
            'licenses': {
                 DialogTitle:       <span className="dialog-title-licenses"><CreditCardIcon />Licenses</span>
                ,DialogContentText: !(oLicenses||{}).TOTAL ? '' :
                    <div className="licenses-table">
                        <div><div>Total number of licenses: </div><div>{   oLicenses.TOTAL  }</div></div>
                        <div><div>Licenses used:            </div><div>{   oLicenses.USED   }</div></div>
                        <div><div>Licenses left:            </div><div>{   oLicenses.UNUSED }</div></div>
                    </div>
                ,secondButtonLabel: 'cancel'
            }
        }[sPopupType] || {};

        return <PopupConfirm
            { ...{ oPopupOptions, sPopupType, set_sPopupType } }
        />

    };

    useEffect( () => {

        const { nRetVal, vOutputRowcounts  } = oResRefreshUsers;

        set_sPopupType('');

        if ( !Number.isNaN(+nRetVal) && ( nRetVal > 0 ) ) {

            const [
                usersUpdated, usersAdded, usersRemovedFromGroups,
                usersRemovedFromSharedLayouts, usersRemovedFromSharedUsers, usersRemovedFromSharedGroups,
                layoutsRemoved, usersRemoved
            ] = vOutputRowcounts.split('-');

            const nChanges = +usersUpdated + +usersAdded + +usersRemoved; // numero di cambiamenti totali

            // se ci sono stati cambiamenti e ci si trova nella pagina degli utenti
            if ( nChanges && ( pathname === '/settings/user-groups' ) ) {
                setbReloadUsers(!bReloadUsers); // vengono ricaricati da database i dati degli utenti
            }
            // } else {
            //     setoNotifica({ sSeverity: 'warning', sMessage: 'No changes in User List' });
            // }
            set_oNotifyOptions({
                severity: 'success',
                timeout:   5,
                message: <div>
                    <span>Import completed</span>
                    <table><tbody>
                    <tr><td>{`Users added:`  }</td><td>{usersAdded   || 0}</td></tr>
                    <tr><td>{`Users updated:`}</td><td>{usersUpdated || 0}</td></tr>
                    <tr><td title={
                            `Users removed from Groups: ${         usersRemovedFromGroups        || 0 }`
                        + `\nUsers removed from Shared Layouts: ${ usersRemovedFromSharedLayouts || 0 }`
                        + `\nUsers removed from Shared Users: ${   usersRemovedFromSharedUsers   || 0 }`
                        + `\nUsers removed from Shared Groups: ${  usersRemovedFromSharedGroups  || 0 }`
                        + `\nLayouts removed: ${                   layoutsRemoved                || 0 }`

                    }>{`Users removed:`}</td><td>{usersRemoved || 0}</td></tr>
                    </tbody></table>
                </div>
            });

        } else if ( !Number.isNaN(+nRetVal) && ( nRetVal < 1 ) ) {
            set_oNotifyOptions({ severity: 'error', message: 'Users update failed' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oResRefreshUsers ]);

    useEffect( () => {
        const { nRetVal /* vOutputRowcounts */ } = oResRebuildPort;

        set_sPopupType('');

        if ( !Number.isNaN(+nRetVal) && ( nRetVal > 0 ) ) {
            set_oNotifyOptions({ severity: 'success', message: 'Import completed', timeout: 5 });
        } else if ( !Number.isNaN(+nRetVal) && ( nRetVal < 1 ) ) {
            set_oNotifyOptions({ severity: 'error',   message: 'Portfolio update failed' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oResRebuildPort ]);

    const getComponent = (route) => {

        if ( route.startsWith('/cubes') ) {
            route = '/cube';
        }

        const loading = {
            get: () => isLoading, // { console.log('isLoading ', isLoading); return isLoading },
            set: set_isLoading    // ( status, logText ) => { console.log('set_isLoading ' + status + ' | ' + logText); return set_isLoading(status) },
        }

        const { CUBE_DESC = '', PROCEDURE_NAME = '' } = ( cubes.find( cube => cube.CUBE_COD === cubeCod ) || {} );

        switch (route) {
            case '/dashboard':
                return <Dashboard userSignOut={logOut}/>;
            case '/cube':
                return <CubeBuilder
                    loading        ={ loading        }
                    authKUser      ={ authKUser      }
                    nCubeCod       ={ cubeCod        }
                    sCubeDesc      ={ CUBE_DESC      }
                    cubeDateTime   ={ cubeDateTime   }
                    PROCEDURE_NAME ={ PROCEDURE_NAME }
                    setLayoutDesc  ={ setLayoutDesc  }
                    maxTabDim      ={ maxTabDim      }
                    drawerOpen     ={ drawerOpen     }
                    drawerWidth    ={ drawerWidth    }
                    sTabSelected   ={ tabSelected    }
                    setTabSelected ={ setTabSelected }
                    nZoom          ={ nZoom          }
                    userSignOut    ={ logOut         }
                />
            case '/impersonate-user':
                return <ImpersonateUser userSignOut={logOut} loading={loading} userImpersonate={userImpersonate} />;
            case '/settings/user-groups':
                return <Groups          userSignOut={logOut} loading={loading} reload={bReloadUsers} />;
            case '/settings/cubes-settings':
                return <CubesSettings   userSignOut={logOut} loading={loading} reload={bReloadCubes} />;
            case '/settings/users-settings':
                return <UsersSettings   userSignOut={logOut} loading={loading} reload={bReloadUsers} />;
            case '/settings/users-editing':
                return <UsersEditing    userSignOut={logOut} loading={loading} reload={bReloadUsers} />;
            default:
                return <Dashboard       userSignOut={logOut}/>;
        }

    }

    const mapZoom = { [-3]: '70', [-2]: '80', [-1]: '90', 0: '100', 1: '110', 2: '120', 3: '130' };
    
    useEffect(() => {
        (async () => {
            if ( isNotificationsEnabled ) {
                console.log('--- Main (inizializzazione) --- get-notifications');
                const aoNotifications = await FetchService.asyncGet({
                    url: '/settings/get-notifications',
                    displayErrorHandler: error => {
                        console.error(error);
                        set_oNotifyOptions({ severity: 'error', message: 'Error retrieving Notifications' });
                    }
                }) || [];
                set_aoNotifications(aoNotifications);
            }
        })();
    }, []);
    
    return (
        <div className={ 'main' + ( nZoom ? ' zoom' + nZoom : '' ) } style={{ height: '100%' }}>

            { /* ----- notifiche ----- */ }
            <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert: handleCloseNotifica } } />

            { /* ----- popup change password ----- */ }
            { !openChangePsw ? null : <ChangePassword { ...{ showState: openChangePsw, set_showState: set_openChangePsw, set_oNotifyOptions } } /> }

            <div className={ classes.root + ( drawerOpen ? ' drawerOpened' : ' drawerClosed' )}>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
                        <Toolbar className={classes.toolbar + ' toolbar ' + ( process.env.REACT_APP_ENV || localStorage.getItem('appid') || 'undefined' ) }>

                            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                <span>{routeTitle}</span><Tooltip title="Last rebuild date"><span className="cubeDateTime">{
                                    Utils.formatDateTime( cubeDateTime, { output: 'D MMM YYYY HH:mm' } )
                                    + cubeNotes
                                }</span></Tooltip><span className="layoutTitle">{layoutTitle}</span>
                            </Typography>
                            
                            { isNotificationsEnabled && (
                                <div>
                                    <Tooltip title="Notifications">
                                        <IconButton type="button" color="inherit" onClick={ handleClickMenuNotifications }>
                                            <NotificationsIcon style={{fontSize: 35}} aria-controls="notifications"/>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        className="menu-notifications"
                                        anchorEl={anchorNotify}
                                        keepMounted
                                        open={Boolean(anchorNotify)}
                                        onClose={handleCloseMenuNotifications}
                                        style={{marginTop: 40}}
                                    >
                                        {
                                            aoNotifications.map( oNotification => 
                                                <MenuItem
                                                    key       ={ oNotification.id }
                                                    className ="menu-top-item"
                                                    onClick   ={ () => {
                                                        oNotification.viewed = true;
                                                        set_aoNotifications([...aoNotifications]);
                                                    }}
                                                >
                                                    <div className="menu-icon-container-notify">
                                                        { ( oNotification.viewed ) 
                                                          ? <CheckCircleIcon className="icon"/>
                                                          : <CheckCircleOutlineIcon className="icon"/>
                                                        }
                                                    </div>
                                                    &nbsp;&nbsp;&nbsp;{ oNotification.message }&nbsp;
                                                    { ( oNotification.viewed ) ? '(Viewed)' : '' }
                                                </MenuItem>
                                            )
                                        }
                                        
                                    </Menu>
                                </div>
                            ) }
                            
                            <div className="zoom out">
                                <Tooltip title={  mapZoom[nZoom - 1] ? 'Zoom out (' + mapZoom[nZoom - 1] + '%)' : '' }>
                                    <IconButton type="button" color="inherit" onClick={ zoomOut } disabled={ nZoom < -2 }>
                                        <ZoomOutIcon style={{fontSize: 35}} aria-controls="zoom-out" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="zoom in">
                                <Tooltip title={ mapZoom[nZoom + 1] ? 'Zoom in (' + mapZoom[nZoom + 1] + '%)' : '' }>
                                    <IconButton type="button" color="inherit" onClick={ zoomIn } disabled={ nZoom > 2 }>
                                        <ZoomInIcon style={{fontSize: 35}} aria-controls="zoom-in" />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            { document.fullscreenEnabled &&
                                <div className="full">
                                    <Tooltip title={ !bFullScreen ? 'Full screen' : 'Exit full screen' }>
                                        <IconButton type="button" color="inherit" onClick={toggleFullScreen}>
                                            { !bFullScreen ? <FullscreenIcon style={{fontSize: 35}} aria-controls="enter-full-screen"/> : <FullscreenExitIcon style={{fontSize: 35}} aria-controls="exit-full-screen"/> }
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }

                            { isAdmin && (
                                <div>
                                    <Tooltip title="Application settings">
                                        <IconButton type="button" color="inherit" onClick={ handleClickMenuSettings }>
                                            <SettingsIcon style={{fontSize: 35}} aria-controls="menu-settings" />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        className   ="menu-settings"
                                        anchorEl    ={anchorSettings}
                                        keepMounted
                                        open        ={Boolean(anchorSettings)}
                                        onClose     ={handleCloseMenuSettings}
                                        style       ={{marginTop: 40}}
                                    >

                                        { FLAG_UPDATE_USERS !== 'Y' ? null : <MenuItem className="menu-top-item" onClick={ ()=> { set_sPopupType('refreshUsers') }}>
                                            <div className='menu-icon-container'>
                                                <PersonOutlineIcon className='icon'/>
                                                <CachedIcon className='sub-icon'/>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;Update Users</MenuItem> }

                                        { FLAG_PORTFOLIO_REBUILD !== 'Y' ? null : <MenuItem className="menu-top-item" onClick={ ()=> { set_sPopupType('rebuildPortfolio') }}>
                                            <div className='menu-icon-container'>
                                                <AttachMoneyIcon className='icon portfolio-icon'/>
                                                <CachedIcon className='sub-icon'/>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;Update Portfolio</MenuItem> }

                                        { ( FLAG_UPDATE_USERS === 'Y' ) || ( FLAG_PORTFOLIO_REBUILD === 'Y' ) ? <Divider /> : null }

                                        <MenuItem className="menu-top-item" onClick={gotoUserGroups}>
                                            <PeopleOutlineIcon/>&nbsp;&nbsp;&nbsp;User Groups</MenuItem>

                                        {/* { true     ? null : <MenuItem className="menu-top-item" onClick={gotoUsersSettings}>
                                            <PeopleOutlineIcon/>&nbsp;&nbsp;&nbsp;User Settings</MenuItem> } */}

                                        { <MenuItem className="menu-top-item" onClick={gotoUsersEditing}>
                                            <div className='menu-icon-container'>
                                                <PersonOutlineIcon className='icon'/>
                                                <SettingsOutlinedIcon className='sub-icon'/>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;Users</MenuItem> }

                                        { <MenuItem className="menu-top-item" onClick={gotoCubesSettings}>
                                            <div className='menu-icon-container'>
                                                <i className="fas fa-cube cube-icon"></i>
                                                <SettingsOutlinedIcon className='sub-icon'/>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;Cubes</MenuItem> }
    
                                        <Divider />
    
                                        <MenuItem className="menu-top-item" onClick={ ()=> { getLicenses(); set_sPopupType('licenses'); }}>
                                            <div className='menu-icon-container'>
                                                <CreditCardIcon />
                                            </div>
                                            &nbsp;&nbsp;&nbsp;Licenses</MenuItem>

                                    </Menu>
                                </div>
                            ) }

                            <Tooltip title="Personal settings">
                                <IconButton className="logged-user-container" color="inherit" onClick={handleClickMenu}>
                                    { isDual && bUsePortfolio
                                      ? <AccountBoxIcon style={{fontSize: 35}} aria-controls="simple-menu" />
                                      : <PersonIcon     style={{fontSize: 35}} aria-controls="simple-menu" />  
                                    }
                                    <div className="logged-user-name">{authFullName}</div>
                                </IconButton>
                            </Tooltip>

                            <Menu
                                className   ="menu-top-user simple-menu"
                                anchorEl    ={anchorEl}
                                keepMounted
                                open        ={Boolean(anchorEl)}
                                onClose     ={handleCloseMenu}
                                style       ={{ marginTop: 40 }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {
                                    isDual && isSelf && (
                                        <MenuItem className="menu-top-item" onClick={ () => { set_bUsePortfolio(!bUsePortfolio); } }>
                                            <div className="use-portfolio">
                                                <span className={ !bUsePortfolio ? 'bold' : '' }>All</span>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color    ="primary"
                                                                checked  ={ !!bUsePortfolio }
                                                                name     ="use-portfolio"
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                </FormGroup>
                                                <span className={ bUsePortfolio ? 'bold' : '' }>Portfolio</span>
                                            </div>
                                        </MenuItem>
                                    )
                                }
                                {
                                    isAdmin && isSelf && (
                                        <MenuItem className="menu-top-item" onClick={impersonateUser}><PeopleIcon/>&nbsp;&nbsp;&nbsp;Impersonate User</MenuItem>
                                    )
                                }
                                {
                                    !isSelf && (
                                        <MenuItem className="menu-top-item" onClick={endImpersonateUser}><PeopleIcon/>&nbsp;&nbsp;&nbsp;End Impersonate</MenuItem>
                                    )
                                }
                                <MenuItem className="menu-top-item" onClick={changePassword}><VpnKeyIcon/>&nbsp;&nbsp;&nbsp;Change Password</MenuItem>
                                <MenuItem className="menu-top-item" onClick={logOut} ><ExitToAppIcon/>&nbsp;&nbsp;&nbsp;Logout</MenuItem>
                            </Menu>

                        </Toolbar>
                    </AppBar>

                    <SideMenu
                        options = {{
                            menuSelected,
                            menuItemClicked,
                            drawerOpen,
                            setDrawerOpen,
                            drawerWidth,
                            cubes,
                            sTabSelected: tabSelected
                        }}
                    />

                    <main className={classes.content} style={{ marginTop: 64 }}>
                        <Container maxWidth={false} className="container-scroll">
                            { getComponent(pathname) }
                        </Container>
                    </main>

                    { isLoading && <Loading/> }

                    { ( sPopupType !== '' ) && createPopupDialog() }

                </MuiThemeProvider>
            </div>

        </div>
    );
}
