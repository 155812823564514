import config from '../config';

export default class FetchService {

    static debug = false;

    static async asyncGet( oRequestParameters ){
        return await this.asyncRequest({ method: 'GET',  ...oRequestParameters });
    }
    static async asyncPost(oRequestParameters ){
        return await this.asyncRequest({ method: 'POST', ...oRequestParameters });
    }

    static async asyncRequest(
        {
            method  = 'GET',
            url,
            params  = '',
            type    = 'json',
            headers = {
                'Accept':       'application/json',
                'Content-Type': 'application/json'
            },
            displayErrorHandler
        }
    ){

        const debug = this.debug;
        const isErrorHandlerNotNull = !!displayErrorHandler;
        if ( typeof displayErrorHandler !== 'function' ) {
            displayErrorHandler = (error) => { console.warn('displayErrorHandler non settato.'); console.error(error); }
        }

        let responseToReturn      = '';
        let sExpectedErrorToShow  = '';
        const sDefaultErrorToShow = 'Connection or application error.';

        debug && console.log(url);

        try {
            
            // prepare params for fetch
            if ( debug && !url )       { console.error('no url specified calling FetchService'); }
            const authToken            = localStorage.getItem('token');
            if ( debug && !authToken ) { console.error('no token in localStorage'); }
            headers['Authorization']   = `Bearer ${authToken}`;
            const appid                = localStorage.getItem('appid') || '';
            if ( debug && !appid )     { console.error('no appid in localStorage'); }
            const env                  = config.ENVIRONMENTS[ appid ];
            if ( debug && !env )       { console.error('no valid env found based on appid: "' + appid + '"'); }
            const apiUrl               = new URL( ( ( env || {} ).API_URL || config.API_URL ) + url );
            const objRequest           = { method, headers, credentials: 'include' }; // 2024-10-28 per CONTAINER rimosso   credentials: 'include'

            if ( params ) {
                if ( method === 'GET') {
                    Object.keys(params).forEach( paramName => apiUrl.searchParams.append(paramName, params[paramName]) );
                } else if ( method === 'POST') {
                    objRequest.body = JSON.stringify( params );
                }
                
            } else if ( debug ) {
                console.error('no paramaters specified calling FetchService');
            }

            // fetch
            const response = await fetch( apiUrl, objRequest );

            // check status
            if ( response.status === 401 ) { // specifico errore intercettato
                sExpectedErrorToShow = 'Invalid credentials or user not enabled';
                localStorage.setItem('token', '');
                localStorage.clear();
                setTimeout(() => { document.location.href = '/'; }, 500);
                throw new Error('Token expired');
                
            } else if ( !( response.status >= 200 && response.status < 300 ) ) {
                // in tutti gli altri casi, se lo status non è tra 200 (compreso) e 300 (escluso), quindi esempio se è 500
                // tentiamo comunque di estrarre e leggere un eventuale errore fornito, altrimenti forziamo un errore
                responseToReturn   = await response.json();
                if ( !responseToReturn || responseToReturn.error ) {
                    sExpectedErrorToShow = ( responseToReturn && responseToReturn.error ) || sDefaultErrorToShow;
                }
                throw new Error( sExpectedErrorToShow );
            }

            // token
            const idToken = response.headers.get('X-Access-Token');
            if ( ( typeof idToken === 'string' ) && ( idToken.trim() !== '' ) ) {
                console.log('X-Access-Token -> refreshToken');
                localStorage.setItem('token', idToken);
            }

            // type of response to output
            if ( ( response.status === 200 ) && ( type === 'blob' ) ) {
                const responseBLOB = await response.blob();
                responseToReturn   = new Blob([responseBLOB], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                
            } else { // se il tipo (che ci si aspetta) è proprio json OPPURE è blob ma lo status è tra 201 e 299 (cioè è valido, ma non è andato a buon fine)

                responseToReturn   = await response.json();

                if ( !responseToReturn || responseToReturn.error ) {
                    sExpectedErrorToShow = ( responseToReturn && responseToReturn.error ) || sDefaultErrorToShow;
                    throw new Error( sExpectedErrorToShow );
                }

            }

        } catch (error) {
            responseToReturn = '';
            isErrorHandlerNotNull && console.error(error); // se è stato passato un ErrorHandler, stampo anche in console l'errore
            ( sExpectedErrorToShow && typeof sExpectedErrorToShow === 'string' ) && console.error(sExpectedErrorToShow);
            displayErrorHandler( sExpectedErrorToShow || sDefaultErrorToShow );
        }

        return responseToReturn;

    }

}
